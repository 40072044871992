import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import Cta from "../components/call_to_action"
import Headings from "../components/sg-headings"
import Reactmarkdown from "react-markdown"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartLine, faCodePullRequest, faChessBoard, faChessKing, faChartSimple, faMagnifyingGlassDollar  } from "@fortawesome/free-solid-svg-icons"


// Add Page Head component and pass in SEO variables from GraphQL
export const Head = ({ data }) => {
  const title = data.strapiService.headline 
  const description = data.strapiGlobal.defaultSeo.metaDescription 
  return (
    <SEO title={title} description={description} />
  )
}

// single service page (strategy on the website)

export default function service ( { data } ) {

  const contentMain = data.strapiService.contentMain.data.contentMain
  const serviceMain = getImage(data.strapiService.serviceMain.localFile)
  const serviceHero = getImage(data.strapiService.heroImage.localFile)
  const heroHeadline = data.strapiService.headline
  const heroDesc = "Customer data and digital platform strategies that will help you use your data to the best effect"
 
  // headings, layout and service 
  return (
    <Layout>
      <div className="relative w-full h-full bg-gradient-to-r from-gray-800 to-red-700">
            <BgImage 
              image={serviceHero} 
              alt="Smoke drifting right" 
              className="absolute h-96 bg-center object-cover mix-blend-overlay" />
                <div className="container absolute top-0 left-1/2 -translate-x-1/2">
                  <Headings title={heroHeadline} description={heroDesc} />
                </div>  
      </div>
      <div className="py-16">  
       <div className="container m-auto px-6 text-gray-600 md:px-12 xl:px-6">
          <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
            <div className="md:5/12 lg:w-5/12"><GatsbyImage className="shadow" image={serviceMain} alt="connecting the dots" /></div>
                <div className="md:7/12 lg:w-6/12">
                  <h2 className="p-1 text-2xl text-gray-900 font-bold md:text-4xl">Connecting the dots</h2>
                  <div className="p-2 space-y-4 prose"><Reactmarkdown>{contentMain}</Reactmarkdown></div>
          </div>
        </div>
      </div>
    </div>
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-1">
    <div className="grid gap-8 row-gap-5 lg:grid-cols-3"> 
    <div className="relative p-5 bg-white rounded-sm">
        <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
            <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full bg-red-400 lg:mb-0">
              <FontAwesomeIcon icon={faChartLine} />
            </div>
          <h6 className="font-semibold leading-5">KPI workshops (project)</h6>
        </div>
        <p className="mb-2 text-sm text-gray-900 prose">
              <Reactmarkdown>{data.strapiService.featureOne.data.featureOne}</Reactmarkdown>
        </p> 
     </div>
     <div className="relative p-5 bg-white rounded-sm">
        <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
            <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full bg-red-400 lg:mb-0">
              <FontAwesomeIcon icon={faCodePullRequest} />
            </div>
          <h6 className="font-semibold leading-5">CDP evaluations (project)</h6>
        </div>
        <p className="mb-2 text-sm text-gray-900 prose">
              <Reactmarkdown>{data.strapiService.featureTwo.data.featureTwo}</Reactmarkdown>
        </p> 
     </div>
     <div className="relative p-5 bg-white rounded-sm">
        <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
            <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full bg-red-400 lg:mb-0">
              <FontAwesomeIcon icon={faChessBoard} />
            </div>
          <h6 className="font-semibold leading-5">Digital Strategy (project)</h6>
        </div>
        <p className="mb-2 text-sm text-gray-900 prose">
              <Reactmarkdown>{data.strapiService.featureThree.data.featureThree}</Reactmarkdown>
        </p> 
     </div>
     </div> 
    </div>
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    <div className="grid gap-8 row-gap-5 lg:grid-cols-3"> 
    <div className="relative p-5 bg-white rounded-sm">
        <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
            <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full bg-red-400 lg:mb-0">
              <FontAwesomeIcon icon={faChessKing} />
            </div>
          <h6 className="font-semibold leading-5">Chief digital officer (fractional)</h6>
        </div>
        <p className="mb-2 text-sm text-gray-900 prose">
             <Reactmarkdown>{data.strapiService.featureFour.data.featureFour}</Reactmarkdown>
        </p> 
     </div>
     <div className="relative p-5 bg-white rounded-sm">
        <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
          <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full bg-red-400 lg:mb-0">
          <FontAwesomeIcon icon={faChartSimple} />
          </div>
        <h6 className="font-semibold leading-5">Chief analytics officer (fractional)</h6>
        </div>
        <p className="mb-2 text-sm text-gray-900 prose">
              <Reactmarkdown>{data.strapiService.featureFive.data.featureFive}</Reactmarkdown>
        </p> 
     </div>
     <div className="relative p-5 bg-white rounded-sm">
        <div className="flex flex-col mb-2 lg:items-center lg:flex-row">
            <div className="flex items-center justify-center w-10 h-10 mb-4 mr-2 rounded-full bg-red-400 lg:mb-0">
              <FontAwesomeIcon icon={faMagnifyingGlassDollar} />
            </div>
          <h6 className="font-semibold leading-5">SEO and content development (both)</h6>
        </div>
        <p className="mb-2 text-sm text-gray-900 prose">
              <Reactmarkdown>{data.strapiService.featureSix.data.featureSix}</Reactmarkdown>
        </p> 
     </div>
     </div> 
     
    </div>
    <Cta />
    </Layout>
  )
}


// query from Strapi
export const query = graphql`
query strapiService {
  strapiService {
    headline
    heroImage {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    contentMain {
      data {
        contentMain
      }
    }
    featureOne {
      data {
        featureOne
      }
    }
    featureTwo {
      data {
        featureTwo
      }
    }
    featureThree {
      data {
        featureThree
      }
    }
    featureFour {
      data {
        featureFour
      }
    }
    featureFive {
      data {
        featureFive
      }
    }
    featureSix {
      data {
        featureSix
      }
    }
    serviceMain {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
  strapiGlobal {
    defaultSeo {
      metaTitle
      metaDescription
    }
  }
}`
